/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/self-closing-comp */
/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-expressions */
/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable prefer-template */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState, useRef } from 'react'
import { Drawer, IconButton } from '@material-ui/core';
import { useReactToPrint } from 'react-to-print';
// import {useParams}  from "react-router-dom";
import "./EditWOModal.css"
import moment from 'moment';
import { toast } from "react-toastify"
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import Activities from './EditComponents/Activities';
import Overview from './EditComponents/Overview';
import StatusTimeLog from './EditComponents/StatusTimeLog';
// import Inventory from './EditComponents/inv/Inventory';
import Checklist from './EditComponents/checlistItems/Checklist';
import {
  setTab as WOTab, deleteWorkOrder, updateWorkOrder, setAssignedTo, setCategory, setCompletedDate, setDescription, setDueFinish, setPriority
  , setStartedDate, setStatus, setTitle, getAttachment, setFixedDate, setTicketType, setWorkOrderType, setSignature, setIsShowCost,
  closeWorkOrderModalDelete,
  openWorkOrderModalDelete,
  setMeterId,
  // setCurrentReading,
  setRequestor,
  setIsSLAEnabled,
  setCompletionNotes,
  closeWorkOrderModalAccept,
  closeWorkOrderModalDecline,
  AcceptAndDecline,
  setReason,
  openWorkOrderModalDecline,
  openWorkOrderModaAccept,
  setStatusType,
  setApprovalDate,
  setApprovalStatus,
  updateMaintenance,
  setCommentSectionOpend,
  setWorkOrder,
  adminAcceptAndDecline,
  openWorkOrderModalCreate,
  resetWorkOrder,
  closeWorkOrderModalCreate,
  openWorkOrderModalUpdate,
  setOpenFrom,
  getMaintenanceById,
  setStoreWorkOrderId,
  setStoreCreatedPMObj,
  closeWorkOrderModalUpdate,
  getWorkOrderById
} from '../../../redux/features/workOrder/workOrderSlice';
import { setLocation } from '../../../redux/features/location/locationSlice';
import { selectActions } from '../../../redux/features/selectOptions/selectSlice';
import { getActivitiesByAuthentication } from '../../../redux/features/ticketActivity/ticketActivitySlice';
import { getLogsByAuthentication } from '../../../redux/features/ticketLog/ticketLogSlice';
import { getTicketinvsByAuthentication } from '../../../redux/features/ticketInventory/ticketInventorySlice';
import { getCompanyProfile } from '../../../redux/features/compay/companySlice';
import { getTickectChecklistById, resetChecklist } from '../../../redux/features/ticketChecklist/ticketChecklistSlice';
import EditPMModal from '../../viewMaintenance/Componets/EditPMModal';
import { closeMaintenanceModalUpdate, openMaintenanceModalUpdate, setTab as PMTab, setMaintenances } from '../../../redux/features/maintenance/maintenanceSlice';

import altCompanyPic from "../../../assets/img/FOX_Logo2.png.webp";
import { getInventorysByAuthentication } from '../../../redux/features/inventory/inventorySlice';
import { getChecklistByAuthentication } from '../../../redux/features/checklist/checklistSlice';
import { getWorkOrderTypeByAuthentication } from '../../../redux/features/workOrderType/workOrderTypeSlice';
import PopUpDeleteModal from '../../../components/popUpModals/editCategory/popUpDeleteModal';
import { getMetersByPagination } from '../../../redux/features/meter/meterSlice';
import NewModal from '../../../components/modals/NewModal';
import IconPrinter from './icons/IconPrinter';
import IconCloseCross from './icons/IconCloseCross';
import IconMessageSquareText from './icons/IconMessageSquareText';
import { getMaintenanceCategoryByAuthentication } from '../../../redux/features/maintenanceCategory/maintenanceCategorySlice';
import { getMaintenanceTypeByAuthentication } from '../../../redux/features/maintenanceType/maintenanceTypeSlice';
import CommentSection from './EditComponents/Comment/CommentSection';
import { getTicketCommentsByAuthentication } from '../../../redux/features/ticketComment/ticketCommentSlice';
import { setAsset } from '../../../redux/features/asset/assetSlice';
import AddWOModal from './AddWOModal';
import Loader from '../../../components/loader/loader';


function EditWOModal({ openModalUpdate, closeModalUpdate }) {
  const {
    openFrom,
    tab,
    title,
    description,
    priority,
    category,
    assigned_to,
    status,
    due_finish,
    start_date,
    completed_date,
    completionNotes,
    // files,
    dueDate,
    workOrderType,
    signature,
    isShowCost,
    workOrderModalDelete,
    workOrderModalAccept,
    workOrderModalDecline,
    reason,
    isCommentSectionOpend,
    isSLAEnabled,
    workOrderModalCreate,
    isWorkOrderGetLoading,
    storeCreatedPMObj
  } = useSelector(state => state.workOrder)
  const { maintenances } = useSelector(state => state.maintenance)
  const [showList, setShowList] = useState(false)
  const { user } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const handelTabs = (e) => {
    dispatch(WOTab(e.target.id))
  }

  const { isWorkOrderGetSuccess, workOrder, meterId, currentReading, uploadMeterAttachment, workOrderApproval }
    = useSelector(state => state.workOrder)
  const { maintenanceModalUpdate } = useSelector(state => state.maintenance)
  const [showPM, setShowPM] = useState(false)

  useEffect(() => {
    dispatch(
      setAssignedTo(workOrder?.assigned_to?.length > 0 ?
        workOrder?.assigned_to?.map((i) => ({
          value: i?.id,
          label: `${i?.name} (${i?.role === "CliAdm" ? "Admin" : i?.role})`,
          image: i?.image
        })) : [])
    )
    dispatch(setCategory(workOrder?.category))
    dispatch(setWorkOrderType(workOrder?.cust_type))
    dispatch(setDescription(workOrder?.description))
    dispatch(setLocation(workOrder?.location?.id))
    if (workOrder?.meter?.id) {
      dispatch(setMeterId({ value: workOrder?.meter?.id, label: workOrder?.meter?.title }))
      // dispatch(setCurrentReading(workOrder?.meter?.current_reading))
    }
    dispatch(setPriority(workOrder?.priority))
    dispatch(setSignature(workOrder?.signature))
    dispatch(setTitle(workOrder?.title))
    dispatch(getAttachment(workOrder?.attachment))
    dispatch(setStatus(workOrder?.status))
    dispatch(setApprovalDate(moment.utc(workOrder?.approval_date).format("YYYY-MM-DDTHH:mm")))
    dispatch(setApprovalStatus(workOrder?.approval_status))
    dispatch(setIsShowCost(workOrder?.display_cost))
    dispatch(setRequestor(workOrder?.requestor_email))
    dispatch(setIsSLAEnabled(workOrder?.enable_sla))
    dispatch(setCompletionNotes(workOrder?.completion_notes))
    dispatch(setDueFinish(moment.utc(workOrder?.due_finish).format("YYYY-MM-DDTHH:mm")))
    dispatch(setFixedDate(moment.utc(workOrder?.due_finish).format("YYYY-MM-DD")))
    dispatch(setStartedDate(moment.utc(workOrder?.start_date).format("YYYY-MM-DDTHH:mm")))
    dispatch(setCompletedDate(moment.utc(workOrder?.completed_date).format("YYYY-MM-DDTHH:mm")))
    dispatch(selectActions.setIdOfSite(workOrder?.location?.id))
    dispatch(selectActions.setIdOfLocation(workOrder?.sub_location?.id))
    dispatch(selectActions.setIdOfAsset(workOrder?.asset?.id))
    if (workOrder?.location?.id) {
      dispatch(setTicketType("locationCheck"))
      dispatch(selectActions.chooseSite({ id: workOrder?.location?.id, selected: workOrder?.location?.site }))
      if (workOrder?.sub_location?.id) {
        dispatch(selectActions.chooseLocation({ id: workOrder?.sub_location?.id, selected: `${workOrder?.sub_location?.room && workOrder?.sub_location?.room}${workOrder?.sub_location?.suite && `, ` + workOrder?.sub_location?.suite}${workOrder?.sub_location?.level && `, ` + workOrder?.sub_location?.level}${workOrder?.sub_location?.building && `, ` + workOrder?.sub_location?.building}` }))
      }
      // dispatch(selectActions.chooseLocation({ id: workOrder?.sub_location?.id, selected: `${workOrder?.sub_location?.room && workOrder?.sub_location?.room}${workOrder?.sub_location?.suite && `, ` + workOrder?.sub_location?.suite}${workOrder?.sub_location?.level && `, ` + workOrder?.sub_location?.level}${workOrder?.sub_location?.building && `, ` + workOrder?.sub_location?.building}` }))
      // dispatch(selectActions.chooseAsset({ id: workOrder?.asset?.id, selected: `${workOrder?.asset?.asset_number} - ${workOrder?.asset?.name} ${workOrder?.asset?.location && `, ` + workOrder?.asset?.location} ${workOrder?.asset.sub_location ? `${workOrder?.asset?.sub_location?.room && `, ` + workOrder?.asset?.sub_location?.room}${workOrder?.asset?.sub_location?.suite && `, ` + workOrder?.asset?.sub_location?.suite}${workOrder?.asset?.sub_location?.level && `, ` + workOrder?.asset?.sub_location?.level}${workOrder?.asset?.sub_location?.building && `, ` + workOrder?.asset?.sub_location?.building}` : ""}` }))

    } else if (workOrder?.asset?.id) {
      dispatch(setTicketType("assetCheck"))
      dispatch(selectActions.chooseAsset({ id: workOrder?.asset?.id, selected: `${workOrder?.asset?.asset_number} - ${workOrder?.asset?.name} ${workOrder?.asset?.location && `, ` + workOrder?.asset?.location}` }))
    } else if (workOrder?.meta_type === "General") {
      dispatch(setTicketType("generalCheck"))
    }
    if (!maintenanceModalUpdate) {
      setShowPM(false)
    }


    if (openFrom === "Maintenance") {
      const d = maintenances?.results?.map((m) =>
        m.id === workOrder.id ? workOrder : m)
      dispatch(setMaintenances({ ...maintenances, results: d }))
    }

  }, [isWorkOrderGetSuccess, workOrder, maintenanceModalUpdate])


  useEffect(() => {
    if (workOrderApproval) {
      dispatch(setWorkOrder({
        ...workOrder,
        approval_date: workOrderApproval?.approval_date,
        approval_status: workOrderApproval?.approval_status,
        status: workOrderApproval?.status,
        completed_date: workOrderApproval?.completed_date,
      }))
    }
  }, [workOrderApproval])


  useEffect(() => {
    dispatch(getWorkOrderTypeByAuthentication())
    dispatch(getMetersByPagination({ rowPerPage: 10, currentPage: 1, SearchVal: "" }));
    if (openFrom === "Maintenance") {
      dispatch(getMaintenanceTypeByAuthentication())
      dispatch(getMaintenanceCategoryByAuthentication())
    }
  }, [])


  useEffect(() => {
    const getWorkOrder = () => {
      if (workOrder?.id) {
        dispatch(getChecklistByAuthentication());
        dispatch(getInventorysByAuthentication());
        dispatch(getActivitiesByAuthentication(workOrder?.id))
        dispatch(getLogsByAuthentication(workOrder?.id))
        dispatch(getTicketinvsByAuthentication(workOrder?.id))
        dispatch(getTicketCommentsByAuthentication(workOrder?.id))
        if (workOrder?.checklist?.length > 0) {
          dispatch(getTickectChecklistById(workOrder?.id));
        } else if (!workOrder?.checklist?.id) {
          dispatch(resetChecklist())
        }
      }
    }
    getWorkOrder()
    dispatch(WOTab("service-tab"))
  }, [isWorkOrderGetSuccess, workOrder, maintenanceModalUpdate])

  useEffect(() => {
    if (dueDate) {
      dispatch(setDueFinish(moment.utc(dueDate?.due_date).format("YYYY-MM-DDTHH:mm")));
    }
  }, [dueDate])


  const {
    idOfSite,
    idOfLocation,
    idOfAsset } = useSelector(state => state.selectOptions)

  // Delete 
  const handelOpenDeletePopup = () => {
    dispatch(openWorkOrderModalDelete())
    setShowList(false);
  };

  const handleDelete = () => {
    dispatch(deleteWorkOrder(workOrder?.id))
  }

  const handleAccept = async () => {
    dispatch(setStatusType("accepted"))
    if (!workOrder?.has_admin_accpeted) {
      await dispatch(adminAcceptAndDecline({ id: workOrder?.id, status: "Accepted" }))
    } else {
      await dispatch(AcceptAndDecline({ id: workOrder?.id, status: "accept" }))
    }
  }
  const handleDecline = async () => {
    if (reason) {
      dispatch(setStatusType("declined"))
      if (!workOrder?.has_admin_accpeted) {
        await dispatch(adminAcceptAndDecline({ id: workOrder?.id, status: "Declined", decline_reason: reason }))
      } else {
        await dispatch(AcceptAndDecline({ id: workOrder?.id, status: "decline", decline_reason: reason }))
      }
    } else {
      toast.warning('Please fill the reason and submit again', {
        toastId: 'reasonWarning',
      });
    }
  }

  const handelReason = (e) => {
    dispatch(setReason(e.target.value))
  }

  const handelSubmit = (e) => {
    e.preventDefault()
    const formData = new FormData()
    if (title) {
      formData.append("title", title)
    }
    if (description) {
      formData.append("description", description)
    }
    if (priority) {
      formData.append("priority", priority)
    }
    if (category) {
      formData.append("category", category)
    }
    if (workOrderType) {
      formData.append("cust_type", workOrderType)
    }
    formData.append("display_cost", isShowCost)

    if (signature && signature?.includes("base64")) {
      const handleSaveFile = (base64String) => {
        // Extract the file format from the base64 string (e.g., 'image/png')
        const format = base64String.split(';')[0].split('/')[1];

        // Remove the data:image/<format>;base64, prefix to get just the base64 data
        const data = atob(base64String.split(',')[1]);

        // Create a Uint8Array to hold the binary data
        const array = new Uint8Array(data.length);

        // Fill the Uint8Array with the binary data
        for (let i = 0; i < data.length; i += 1) {
          array[i] = data.charCodeAt(i);
        }

        // Create a Blob from the Uint8Array
        const blob = new Blob([array], { type: `image/${format}` });

        // Create a File object with the desired properties
        const fileName = 'image (3).png'; // Replace with your desired filename
        const lastModified = Date.now(); // Replace with the last modified timestamp if needed
        const file = new File([blob], fileName, { type: blob.type, lastModified });
        return file
      };
      formData.append('signature', handleSaveFile(signature));
    }

    if (due_finish) {
      formData.append("due_finish", moment.utc(due_finish).format("YYYY-MM-DDTHH:mm:ss.SSZ"))
    }
    if (assigned_to?.length > 0) {
      assigned_to?.forEach((ele) => (
        formData.append(`assigned_to`, ele?.value)
      ))
    }


    formData.append("enable_sla", isSLAEnabled)


    formData.append("status", status)
    if (status === "Completed" || status === "Complete (Pending Approval)") {
      formData.append("completed_date", moment.utc(completed_date).format("YYYY-MM-DDTHH:mm:ss.SSZ"))
      formData.append(`completion_notes`, completionNotes)
    }

    // if (status !== "Completed" ) {
    //   formData.append(`completion_notes`, "")
    // }
    formData.append("start_date", start_date !== "Invalid date" ? moment.utc(start_date).format("YYYY-MM-DDTHH:mm:ss.SSZ") : "")
    if (idOfAsset) {
      formData.append("asset", idOfAsset)
    }
    if (idOfSite) {
      formData.append("location", idOfSite)
    }
    if (idOfLocation) {
      formData.append("sub_location", idOfLocation)
    }
    if (meterId?.value) {
      formData.append("meter", meterId?.value)
      if (currentReading) {
        formData.append("current_reading", currentReading)
      }
      if (uploadMeterAttachment) {
        formData.append("reading_attachment", uploadMeterAttachment)
      }
    }
    if (title && due_finish && assigned_to.length > 0 && category) {
      if (status === "Completed" && (completed_date === null || completed_date === "Invalid date")) {
        toast.warning('Please fill the Completed Date field and submit again', {
          toastId: 'CreatedEventWarning',
        });
        return
      }
      if (openFrom === "Maintenance") {
        dispatch(updateMaintenance({ id: workOrder?.id, formData }))
      } else {
        dispatch(updateWorkOrder({ id: workOrder?.id, formData }))
      }
    }

    else {
      toast.warning('Please fill the required fields and submit again', {
        toastId: 'CreatedEventWarning',
      });
    }
  }



  const { profile } = useSelector((state) => state.company);
  const [info, setInfo] = useState({
    name: '',
    address: '',
    phone_number: '',
    website: '',
    logo: "",
  });

  const dispatchProfile = useDispatch();

  useEffect(() => {
    dispatchProfile(getCompanyProfile());
  }, []);
  useEffect(() => {
    setInfo({
      name: profile?.name,
      address: profile?.address,
      phone_number: profile?.phone_number,
      website: profile?.website,
      logo: ((profile?.logo !== null && profile?.logo !== undefined) ? profile?.logo : info.logo)
    });
  }, [profile]);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });


  const { logs } = useSelector(state => state.logTime)
  const { ticketinvs } = useSelector(state => state.ticketinv)
  const { ticketChecklists } = useSelector(state => state.ticketChecklist)

  const totalLaborCost = logs?.reduce(
    (accumulator, currentValue) =>
      accumulator + (+currentValue?.labor_cost || 0),
    0
  );

  const totalPartCost = ticketinvs?.reduce(
    (accumulator, currentValue) =>
      accumulator + (+currentValue?.total_cost || 0),
    0
  );

  const total = (totalLaborCost + totalPartCost).toFixed(2);

  let deleteBtn = <li role="presentation">
    <button type='button' className="font-danger"
      title="Delete Work Order"
      onClick={handelOpenDeletePopup}>  Delete </button>
  </li>

  if ((user?.role === "Technical Team") ||
    (user?.role === "Requester") ||
    (user?.role === "Manager" && workOrder?.service_request?.created_by?.role !== "Manager")) {
    deleteBtn = ""
  }

  const isTechCanEdit = user?.role === "Technical Team"
    && workOrder?.service_request?.created_by?.role !== "Technical Team"

  const isAdminAccept = workOrder?.has_admin_accpeted
  const isAssigned = workOrder?.assigned_to?.map(item => item.id)?.includes(user?.id)

  const isExternal = () => {
    if (workOrder?.type === "External") {
      if (isAdminAccept && user?.role === "CliAdm") {
        return true;
      }
    }
    if (workOrder?.type === "Internal" && user?.role === "CliAdm") {
      return true;
    }
    if (isAssigned && (workOrder?.status !== "Pending" && workOrder?.status !== "Declined")) {
      return true;
    }
    return false;
  }

  const { ticketComments, isTicketCommentsGetLoading } = useSelector(state => state.ticketComment)

  const addworkOrderHandler = () => {
    dispatch(setStoreWorkOrderId(workOrder?.id))
    dispatch(resetWorkOrder("Without-assined-to"))
    dispatch(WOTab("maintenance-tab"))
    dispatch(openWorkOrderModalCreate())
  }

  const handleOpenPMFromWO = async (tkId) => {
    dispatch(setStoreCreatedPMObj(workOrder?.id))
    dispatch(setWorkOrder({}))
    dispatch(resetWorkOrder())
    dispatch(setAsset(null))
    dispatch(dispatch(selectActions.reset()))
    await dispatch(getMaintenanceById(tkId));
    dispatch(openWorkOrderModalUpdate());
    dispatch(setOpenFrom("Maintenance"));
    dispatch(setStoreWorkOrderId(tkId))
  }

  const closeWOModal = async () => {
    if (storeCreatedPMObj) {
      dispatch(setWorkOrder({}))
      dispatch(resetWorkOrder())
      dispatch(setAsset(null))
      dispatch(dispatch(selectActions.reset()))
      await dispatch(getWorkOrderById(storeCreatedPMObj));
      dispatch(openWorkOrderModalUpdate());
      dispatch(setOpenFrom(""));
      dispatch(setStoreCreatedPMObj(null))
    } else {
      dispatch(closeWorkOrderModalUpdate())
    }
  }

  return isWorkOrderGetLoading ?
    <Loader /> :
    <Drawer
      anchor="right"
      open={openModalUpdate}
      onClose={closeWOModal}
      PaperProps={{
        style: {
          width: '100%',
          maxWidth: isCommentSectionOpend ? '920px' : "600px",
          // maxWidth: "920px",
          height: '100%',
        },
      }}
    >
      {!showPM &&
        <>
          <div className="h-100 w-100" >
            <div className="navbar col-lg-12 m-0 modal-header-background page-profile-header pr-0"
              style={{ backgroundColor: '#F2F2F3' }}
            >
              <div style={{
                width: '100%',
                marginTop: '10px',
                marginLeft: '8px',
                marginRight: '16px'
              }}>
                <div style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  marginBottom: '12px'
                }}>
                  <div style={{
                    fontWeight: '600',
                    fontSize: '14px',
                  }}>
                    {workOrder?.created_from ?
                      <span onClick={() => handleOpenPMFromWO(workOrder?.created_from?.id)} style={{ fontSize: "12px" }}> <span className='create-from'>
                        {workOrder?.created_from?.ticket_number} </span>
                        >
                      </span> : ""}
                    {openFrom === "Maintenance" ? ` ${workOrder?.ticket_number}` : ` #${workOrder?.ticket_number}`}
                  </div>
                  <div style={{ display: 'flex', gap: '8px' }}>
                    {openFrom === "Maintenance" && <Button variant="contained" style={{
                      backgroundColor: '#F8F9F9',
                      borderRadius: '8px',
                      paddingRight: "7px",
                      paddingLeft: "7px"
                    }}
                      onClick={addworkOrderHandler}
                      className='outlined-button create-wo-from-pm'>
                      Create Work Order
                    </Button>}
                    <Button variant="contained"
                      className={`comment-button ${isCommentSectionOpend ? "active" : ""}`}
                      style={{
                        paddingRight: "7px",
                        paddingLeft: "7px"
                      }}
                    >
                      <div onClick={() => dispatch(setCommentSectionOpend(!isCommentSectionOpend))} style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <IconMessageSquareText />
                        <span className='comment-button-text' style={{ paddingTop: '3px' }}>
                          {isTicketCommentsGetLoading ? "...Loading" : `${ticketComments?.length} Comments`}
                        </span>
                      </div>
                    </Button>
                    <IconButton variant="contained" style={{
                      backgroundColor: '#F8F9F9',
                      borderRadius: '8px',
                      border: '1px solid #E1E1E1'
                    }}
                      onClick={handlePrint}
                      className='outlined-button'>
                      <IconPrinter />
                    </IconButton>
                    <IconButton variant="contained" style={{
                      backgroundColor: '#F8F9F9',
                      borderRadius: '8px',
                      border: '1px solid #E1E1E1'
                    }}
                      onClick={closeWOModal}
                      className='outlined-button'>
                      <IconCloseCross />
                    </IconButton>
                  </div>
                </div>
                <div className="row justify-content-start mt-auto col-lg-12 col-sm-12">
                  <ul className="nav nav-pills" id="custom-tabs-two-tab" role="tablist" >
                    <li className="nav-item">
                      <span onClick={handelTabs}
                        className={`nav-link ${tab === "service-tab" && "active"} cursor-pointer`}
                        id="service-tab" data-toggle="tab" role="tab" >Overview</span>
                    </li>
                    {user?.role !== "Requester" && <> <li className="nav-item">
                      <span onClick={handelTabs}
                        className={`nav-link ${tab === "status-time-tab" && "active"} cursor-pointer`}
                        // id="status-time-tab" data-toggle="tab" role="tab" >Status & Time Log</span>
                        id="status-time-tab" data-toggle="tab" role="tab" >Status</span>
                    </li>
                      <li className="nav-item">
                        <span onClick={handelTabs}
                          className={`nav-link ${tab === "checklist-tab" && "active"} cursor-pointer`}
                          id="checklist-tab" data-toggle="tab" role="tab"  >Checklist</span>
                      </li>
                    </>
                    }
                    <li className="nav-item">
                      <span onClick={handelTabs}
                        className={`nav-link ${tab === "activity-tab" && "active"} cursor-pointer`}
                        id="activity-tab" data-toggle="tab" role="tab" >Activities</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>




            <div className='wo-tabs'>
              <div className="tab-content" style={{ height: "80vh", overflow: "scroll" }} id="custom-tabs-two-tabContent">
                {tab === "service-tab" && <Overview isTechCanEdit={isTechCanEdit} />}
                {tab === "status-time-tab" && <StatusTimeLog id={workOrder?.id} isTechCanEdit={isTechCanEdit} isExternal={isExternal} />}
                {tab === "checklist-tab" && <Checklist id={workOrder?.id} />}
                {tab === "activity-tab" && <Activities id={workOrder?.id} />}
              </div>
              {isCommentSectionOpend && <div className='wo-comment-tab'
                style={{ height: "80vh", overflow: "scroll" }}>
                <CommentSection />
              </div>}
            </div>

            <div className="m-0 p-2 page-profile-footer-edit-wo" >
              <div className='flex' style={((workOrder?.status !== "Pending" && isAdminAccept) || (!isAssigned && (workOrder?.type !== "External" || isAdminAccept))) ? { flex: "1", justifyContent: "space-between", alignItems: "end" } : {}}>
                <button
                  className="btn btn-primary"
                  type="submit"
                  onClick={handelSubmit}
                  disabled={(!isExternal() || workOrder?.status === "Declined")}
                >
                  <span className="button__text">Save</span>
                </button>
                <div className='pl-2 my-auto'>
                  {((workOrder?.status !== "Pending" && isAdminAccept) || (!isAssigned && (workOrder?.type !== "External" || isAdminAccept))) && <div style={{ textAlign: "right" }}>Last updated on</div>}
                  <div className="pl-2 my-auto gcolor footer-date date-wo">
                    <span style={{ fontSize: "14px" }} >{moment(workOrder?.modified_at).format('DD MMMM YYYY h:mm A')}</span>
                  </div>
                </div>
              </div>
              {((workOrder?.status === "Pending" && isAssigned) || !workOrder?.has_admin_accpeted) && <div className='flex acc-dec'>
                <button
                  className="decline-btn mr-2"
                  type="button"
                  onClick={() => dispatch(openWorkOrderModalDecline())}
                >
                  <span className="button__text">Decline</span>
                </button>
                <button
                  className="accept-btn"
                  type="button"
                  onClick={() => dispatch(openWorkOrderModaAccept())}
                >
                  <span className="button__text">Accept</span>
                </button>
              </div>}
            </div>
          </div>
          <div className="card-body"
            style={{ paddingBottom: '1.25rem', display: "none" }}
            ref={componentRef}>
            <div className="assetPdf">
              <div className='pdfheader'>
                <img
                  src={info.logo ? info.logo : altCompanyPic}
                  alt='Company logo'
                  className='brand-image img-company'
                  id='result_company_base'
                  height='100'
                  width='100'
                  style={{ marginRight: '20px' }}
                />
                <div> <strong>{info.name}</strong>
                  <br />
                  <span>{info.address}</span> <br />
                  <span>Tel: {info.phone_number ? info.phone_number : "—"}</span> <span style={{ marginLeft: '10px' }}> Website: {info.website ? info.website : "—"}</span>
                </div>
              </div>
              <div className="firstHalf">
                <div className="right">
                  <div><strong>Requester Particulars</strong></div>
                  <div>{workOrder?.service_request?.name}</div>
                  <div>{`Phone : ${workOrder?.service_request?.phone || "Unknown"}`}</div>
                  <div>{`Email: ${workOrder?.service_request?.email}`}</div>
                  <div>{`Issued on : ${moment(workOrder?.created_at).format('MMMM Do YYYY, h:mm:ss a')}`}</div>
                  <div>{`Service Date: ${moment(due_finish).format("YYYY-MM-DD-hh:mm")} `}</div>
                  <div>{`Last Updated : ${moment(workOrder?.modified_at).format('MMMM Do YYYY, h:mm:ss a')}`}</div>
                </div>
                <div className="left">
                  <div> <strong>{`Work Order #: ${workOrder?.ticket_number}`}</strong></div>
                  {workOrder?.status && (<div>{`Status : ${workOrder?.status}`}</div>)}
                  {/* {workOrder?.status === "Completed" && (<div>{`Completed Date: ${moment(workOrder?.completed_date).format("YYYY-MM-DD-hh:mm")}`}</div>)} */}
                  {workOrder?.priority && (<div>{`Priority: ${workOrder?.priority}`}</div>)}
                  {workOrder?.category && (<div>{`Category: ${workOrder?.category}`}</div>)}
                  {workOrder?.assigned_to?.length > 0 && (<div>{`Assigned To: ${workOrder?.assigned_to?.map(a => a?.name).join(", ")}`}</div>)}
                </div>
              </div>
              <br />
              <br />
              <div className='main-border'>
                <div className='header-of-feature'><h6>WORK ORDER</h6></div>
                <div className='body-of-feature'>
                  <div><h6 style={{ fontSize: '20px', fontWeight: "bold", display: "inline" }}>Title: </h6><span style={{ fontSize: '20px' }}>{workOrder?.title}</span> </div>
                  {workOrder?.description && (<div><h6 style={{ fontSize: '20px', fontWeight: "bold", display: "inline" }}>Description: </h6><span style={{ fontSize: '20px' }}>{workOrder?.description}</span></div>)}
                </div>
              </div>

              {(workOrder?.asset || workOrder?.location?.site) && (
                <>
                  <br />
                  <br />
                  <div className='main-border'>
                    <div className='header-of-feature'><h6>DETAILS</h6></div>
                    <div className='body-of-feature'>
                      {workOrder?.asset &&
                        <div className='mb-2'>
                          <div style={{ fontSize: '20px', fontWeight: "bold" }}>Asset:</div>
                          {workOrder?.asset?.name && (
                            <div>Name: {workOrder?.asset.name}</div>
                          )}
                          {workOrder?.asset?.category && (
                            <div>Category: {workOrder?.asset.category}</div>
                          )}
                          {workOrder?.asset?.asset_number && (
                            <div>Serial Number: {workOrder?.asset.asset_number}</div>
                          )}
                          {workOrder?.asset?.asset_type && (
                            <div>Type: {workOrder?.asset.asset_type}</div>
                          )}
                          {!workOrder?.location?.site && workOrder?.asset?.location && (
                            <div>Location: {workOrder?.asset.location}</div>
                          )}
                        </div>}
                      {workOrder?.location?.site &&
                        <>
                          <div style={{ fontSize: '20px', fontWeight: "bold" }}>Location:</div>
                          {workOrder?.location?.site && (
                            <div>Location: {workOrder.location.site}</div>
                          )}
                          {workOrder?.sub_location?.room && (
                            <div>Sublocation: {workOrder.sub_location.room}</div>
                          )}
                        </>}
                    </div>
                  </div>
                </>
              )}

              {workOrder?.attachment && workOrder?.attachment.length > 0 && (
                <>
                  <br />
                  <br />
                  <div className='main-border' style={{ breakInside: 'avoid' }}>
                    <div className='header-of-feature'><h6>Attachment</h6></div>
                    <div className='body-of-feature'>
                      <ul style={{ display: 'flex', flexWrap: "wrap" }}>
                        {workOrder.attachment.map((item) => (
                          <li style={{ width: "250px", marginRight: '30px' }}>
                            <img
                              src={item.photo}
                              alt={item?.caption}
                              className='brand-image img-company'
                              id='result_company_base'
                              style={{ width: "100%", height: "195px" }}
                            />
                            {item?.caption && <p style={{ marginTop: "8px", paddingLeft: "5px" }}>{item?.caption}</p>}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </>
              )
              }

              {ticketChecklists?.length > 0 && (
                <>
                  <br />
                  <br />
                  <div className='main-border'>
                    <div className='header-of-feature'><h6>CHECKLIST {ticketChecklists?.title}</h6></div>
                    <div className='body-of-feature'>
                      <table
                        id="service-history-list"
                        className="table table-togglable w-100"
                      >
                        <thead>
                          <tr>
                            <th><b><center>Checklist</center></b></th>
                            <th><b><center>Task</center></b></th>
                            <th><b><center>Status</center></b></th>
                            <th><b><center>Attachment</center></b></th>
                            <th><b><center>Notes</center></b></th>
                          </tr>
                        </thead>
                        <tbody>
                          {ticketChecklists.length > 0 && ticketChecklists?.map((check) => (
                            check.items.length > 0 && check.items.map((item, idx) => (
                              <tr key={check?.id}>
                                <td><center><b>  {idx === 0 ? check.title : ""}</b> </center></td>
                                <td><center><b>{item.name}</b> </center></td>
                                <td><center>  <b>{item.result ? item.result : ""}</b>  </center></td>
                                <td><center>  {item.attachment ?
                                  <img src={item.attachment}
                                    style={{ width: "100px", height: "80px" }} alt={item.name}
                                    className='brand-image img-company'
                                    id='result_company_base'
                                  /> : ""}
                                </center>
                                </td>
                                <td>
                                  <center><span className="d-none"></span>
                                    {item?.remarks}</center>
                                </td>
                              </tr>
                            ))
                          ))}

                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              )
              }


              {isShowCost && (ticketinvs?.length > 0 || logs?.length > 0) && (
                <>
                  <br />
                  <br />
                  <div className='main-border'>
                    <div className='header-of-feature'><h6>Cost</h6></div>
                    <div className='body-of-feature'>
                      {logs?.length > 0 &&
                        <>
                          <div style={{ fontSize: '20px', fontWeight: "bold" }}>Labor Cost:</div>
                          <table
                            id="service-history-list"
                            className="table table-togglable w-100"
                          >
                            <thead>
                              <tr>
                                <th><center>Time Taken</center></th>
                                <th><center>Hourly Rate </center></th>
                                <th><center>Name </center></th>
                                <th><center>Date</center></th>
                                <th><center>Labor Cost</center></th>
                              </tr>
                            </thead>
                            <tbody>
                              {logs?.length > 0 && logs?.map(log => (
                                <tr key={log?.id}>
                                  <td><center><b>{log?.hours} Hours {log?.minutes} Minutes </b> </center></td>
                                  <td>
                                    <center><span className="d-none"></span>
                                      ${log?.hourly_rate}</center>
                                  </td>

                                  <td>
                                    <center>
                                      {log?.created_by?.first_name} {log?.created_by?.last_name} ({log?.created_by?.role === "CliAdm" ? "Admin" : log?.created_by?.role})
                                    </center>
                                  </td>

                                  <td><center>{moment(log?.work_date).format("YYYY-MM-DD")}</center></td>
                                  <td><center>${log?.labor_cost}</center></td>
                                </tr>
                              ))}
                              <tr>
                                <td></td>
                                <td>
                                </td>
                                <td>
                                </td>
                                <td><center> <strong>TOTAL LABOR COST</strong> </center></td>
                                <td><center><strong>${totalLaborCost}</strong></center></td>
                              </tr>

                            </tbody>
                          </table>
                        </>}
                      {ticketinvs?.length > 0 && <>
                        <div style={{ fontSize: '20px', fontWeight: "bold" }}>Parts Used:</div>
                        <table
                          id="service-history-list"
                          className="table table-togglable w-100"

                        >
                          <thead>
                            <tr>
                              <th><center>#</center></th>
                              <th><center>Name</center></th>
                              <th><center>Quantity Used </center></th>
                              <th><center>Cost</center></th>
                            </tr>
                          </thead>
                          <tbody>
                            {ticketinvs?.length > 0 && ticketinvs?.map(inv => (
                              <tr key={inv?.id}>
                                <td><center><b>{inv.id}</b> </center></td>
                                <td>
                                  <center><span className="d-none"></span>
                                    {inv?.name}</center>
                                </td>

                                <td>
                                  <center>
                                    {inv?.total_used}
                                  </center>
                                </td>

                                <td><center>${inv?.cost}</center></td>
                              </tr>
                            ))}
                            <tr>
                              <td></td>
                              <td>
                              </td>
                              <td><center> <strong>TOTAL PARTS COST</strong> </center></td>
                              <td><center><strong>${totalPartCost}</strong></center></td>
                            </tr>
                          </tbody>
                        </table>
                      </>
                      }
                      <br />
                      <br />
                      {isShowCost &&
                        <>
                          <div style={{ fontSize: '20px', fontWeight: "bold" }}>Total Cost:</div>
                          <div className="totalcostbody">
                            <div className="right">
                              <div><strong>Details</strong></div>
                              <strong><div>Labor Cost</div></strong>
                              <strong><div>Part Cost</div></strong>
                            </div>
                            <div className="left">
                              <div><strong>Cost</strong></div>
                              <strong><div>${totalLaborCost}</div></strong>
                              <strong><div>${totalPartCost}</div></strong>
                            </div>
                          </div>
                          <div className="total">
                            <div className="right">
                              <strong><div>TOTAL COST </div></strong>
                            </div>
                            <div className="left" style={{ paddingRight: "0.5rem" }}>
                              <strong><div>${total}</div></strong>
                            </div>
                          </div>
                        </>
                      }

                    </div>
                  </div>
                </>
              )
              }
              <br />
              <br />
              <br />
              <div style={{ breakInside: 'avoid' }}>
                {workOrder?.status === "Completed" && workOrder?.completion_notes &&
                  <div className='main-border'>
                    <div className='header-of-feature'><h6>Completion Notes</h6></div>
                    <div className='body-of-feature'>
                      <div className='mb-2'>
                        <span>{workOrder?.completion_notes}</span>
                      </div>
                    </div>

                  </div>
                }
                <br />
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end", breakInside: 'avoid' }}>
                  <div style={{ width: '200px' }}>
                    {workOrder?.status === "Completed" && workOrder?.signature &&
                      <div>
                        <img className="sign-image" src={workOrder?.signature}
                          style={{ height: '60px', width: "200px", marginLeft: "5px", marginBottom: "5px" }} alt="Signature" />
                      </div>
                    }
                    <div style={{ borderTop: '1px solid black' }}>Signature</div>
                  </div>
                  <div style={{ width: '200px' }}>
                    {workOrder?.status === "Completed" &&
                      <div>
                        {moment(workOrder?.completed_date).format('YYYY-MM-DD')}
                      </div>
                    }
                    <div style={{ borderTop: '1px solid black' }}>Date</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      }
      {showPM && maintenanceModalUpdate && <EditPMModal
        openModalUpdate={maintenanceModalUpdate}
        closeModalUpdate={() => dispatch(closeMaintenanceModalUpdate())}
      />}
      {workOrderModalDelete && (
        <PopUpDeleteModal
          showModal={workOrderModalDelete}
          handleClose={() => dispatch(closeWorkOrderModalDelete())}
          handleConfirm={handleDelete}
          modalTitle={`Delete ${workOrder?.title}`}
          modalBodyText="Are you sure you want to delete this work order?"
        />
      )}
      {workOrderModalAccept && (
        <NewModal
          showModal={workOrderModalAccept}
          handleClose={() => dispatch(closeWorkOrderModalAccept())}
          handleCreateSave={handleAccept}
          primaryButtonText="Accept"
          modalTitle="Accept this work order?"
        >
          {workOrder?.has_admin_accpeted ?
            "System will send a notification to the requester and the task will move to “Accepted” tabs" :
            "The system will unlock the details, allowing you to assign the task to a technician."
          }
        </NewModal>
      )}
      {workOrderModalDecline && (
        <div className='modal-wrapper'>
          <NewModal
            showModal={workOrderModalDecline}
            handleClose={() => dispatch(closeWorkOrderModalDecline())}
            handleCreateSave={handleDecline}
            primaryButtonText="Decline"
            modalTitle="Decline this work order?"
          >
            <div className="col-lg-12 col-sm-12" onClick={(e) => e.stopPropagation()}>
              <textarea
                className="form-control mb-2"
                name="description"
                id="meter_description"
                placeholder="Please state your reason*"
                onChange={handelReason}
                value={reason}
              ></textarea>
            </div>
            <div className="col-lg-12 col-sm-12">
              System will send a notification to the requester and the task will move to “Declined” tabs
            </div>
          </NewModal>
        </div>
      )}

      {workOrderModalCreate && <AddWOModal
        openModalCreate={workOrderModalCreate}
        closeModalCreate={() => dispatch(closeWorkOrderModalCreate())}
        createdFrom={workOrder?.id}
      />}
    </Drawer>
}

export default EditWOModal




















