import './styles/style.css';
import './styles/globals.css';
import './styles/tailwind.css';
import './index.css';
import { Route, Switch, Redirect } from 'react-router-dom';
import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { useDispatch } from 'react-redux';
import {
  AnalyticsReport,
  AssetsPage,
  Checklists,
  Dashboard,
  EditCategory,
  Inventories,
  Locations,
  Meter,
  Teams,
  ViewMaintenance,
  WorkOrders,
  Login,
  Profile,
  ForgetPassword,
  ResetPasswodByEmail,
  UserResetNewPassword,
  ResetPassword,
  CompanyProfile,
} from './pages';
import AssetMapping from './pages/assetMapping/assetMapping';
import Layout from './components/layout/layout';
import AddAsset from './pages/assetsPage/addAsset/addAsset';
import FetchAsset from './pages/assetsPage/fetchAsset/fetchAsset';
import ScrollToTop from './components/scrollToTop';
import AssetQRCode from './pages/assetsPage/assetQrCode';
import LocationQRCode from './pages/locations/locationQrCode';
import TicketView from './pages/workOrder/ticketView/ticketView';
import LocationHistory from './components/location/LocationHistory';
import AssetHistory from './components/assetsTable/assetsHistory';
import InventoryHistory from './components/inventory/inventoryHistory';
import { logout } from './redux/features/auth/authSlice';
// import { changeFavicon } from './utils/changeFavicon';
import QrCodeResult from './pages/inventories/QrCodeResult';
import Settings from './pages/settings/settings';
import { FRONT_URL } from './utils/axios';
import viewEvent from './pages/viewEvent/viewEvent';

// eslint-disable-next-line react/prop-types
function PrivateRoute({ component: Component, path, exact }) {
  return (
    <Route
      path={path}
      exact={exact}
      render={(props) =>
        localStorage.getItem('userToken') ? (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
}

function App() {
  const dispatch = useDispatch();
 
  useEffect(() => {
    const getToken = () => {
      window.addEventListener('message', (e) => {
        if (e.origin !== FRONT_URL) {
          return;
        }
        const data = JSON.parse(e.data);
        localStorage.setItem('userToken', JSON.stringify(data));
        // localStorage.setItem("refreshToken" , JSON.stringify(data.refreshToken))
      });
    };
    getToken();
  }, []);

  const checkTokenExpiration = () => {
    const storedToken = localStorage.getItem('userToken');
    if (storedToken) {
      const { expiration } = JSON.parse(storedToken);
      const currentTime = new Date().getTime();
      if (currentTime > expiration) {
        // Token has expired, log out the user
        localStorage.removeItem('userToken');
        // Redirect to the login page or show a logged-out state
        dispatch(logout());
        window.location.href = FRONT_URL;
        // history.push('/login');
      }
    }
  };

  useEffect(() => {
    // Call checkTokenExpiration every sec (1 * 1000 milliseconds)
    const tokenExpirationInterval = setInterval(checkTokenExpiration, 1 * 1000);
    // Clean up the interval on component unmount
    return () => clearInterval(tokenExpirationInterval);
  }, []);

  const token = localStorage.getItem('userToken');
  return (
    <div className="App">
      <main>
        <ScrollToTop />
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/forgot-password" component={ForgetPassword} />
          <Route exact path="/confirm-password" component={ResetPasswodByEmail} />
          <Route exact path="/user-new-password/:uidb/:token" component={UserResetNewPassword} />
          <Route exact path="/asset-service" component={AssetQRCode} />
          <Route exact path="/site-service" component={LocationQRCode} />
          <Route exact path="/ticket-view" component={TicketView} />
          <Route exact path="/inventory-service" component={QrCodeResult} />
          <PrivateRoute exact path="/" component={AnalyticsReport} />
          <PrivateRoute exact path="/event" component={viewEvent} />
          <PrivateRoute exact path="/planner" component={Dashboard} />
          <PrivateRoute exact path="/maintenance" component={ViewMaintenance} />
          <PrivateRoute exact path="/workOrders" component={WorkOrders} />
          <PrivateRoute exact path="/meter" component={Meter} />
          <PrivateRoute exact path="/inventories" component={Inventories} />
          <PrivateRoute exact path="/locations" component={Locations} />
          <PrivateRoute exact path="/assets" component={AssetsPage} />
          <PrivateRoute exact path="/addAsset" component={AddAsset} />
          <PrivateRoute exact path="/assets/:id" component={FetchAsset} />
          <PrivateRoute exact path="/sub-location-history/:id" component={LocationHistory} />
          <PrivateRoute exact path="/asset-history/:id" component={AssetHistory} />
          <PrivateRoute exact path="/inventory-history/:id" component={InventoryHistory} />
          <PrivateRoute exact path="/assetMapping" component={AssetMapping} />
          <PrivateRoute exact path="/teams" component={Teams} />
          <PrivateRoute exact path="/checklists" component={Checklists} />
          <PrivateRoute exact path="/editCategory" component={EditCategory} />
          <PrivateRoute exact path="/profile" component={Profile} />
          <PrivateRoute exact path="/change-password" component={ResetPassword} />
          <PrivateRoute exact path="/company-profile/" component={CompanyProfile} />
          <PrivateRoute exact path="/settings" component={Settings} />
          {token ? <Redirect to="/" /> : <Redirect to="/login" />}
        </Switch>
      </main>
      <ToastContainer draggable={false} autoClose={2000} limit={1} />
    </div>
  );
}

export default App;
