import React, { useEffect, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import bootstrap from '@fullcalendar/bootstrap';
import './calendar.css'

/* eslint-disable react/jsx-boolean-value */
// eslint-disable-next-line react/prop-types

export default function Calendar({ handleEventClick, events }) {
  const statusOrder = {
    "In-Progress": 1,
    "On-Hold": 2,
    "Open": 3,
    "Completed": 4,
  };
  const sortEventsByTime = () => {
    return [...events]?.sort((a, b) => {
      const statusComparison = statusOrder[a.status] - statusOrder[b.status];
      // If statuses are the same, then sort by time
      if (statusComparison === 0) {
        const startA = new Date(a.due_finish);
        const startB = new Date(b.due_finish);
        return startA.getTime() - startB.getTime();
      }
      if (a.reading_date || b.reading_date) {
        const startA = new Date(a.reading_date);
        const startB = new Date(b.reading_date);
        return startA.getTime() - startB.getTime();
      }
      return statusComparison;
    });
  };


  // Inside your component
  const calendarRef = useRef(null);

  const [sortedData, setSortedData] = useState([])

  useEffect(() => {
    setSortedData(sortEventsByTime())
    // Trigger calendar to refetch events
    if (calendarRef.current) {
      calendarRef.current.getApi().refetchEvents();
    }
  }, [events])



  return (
    <FullCalendar
      ref={calendarRef}
      key={sortedData.map(event => event.id).join(',')}
      plugins={[dayGridPlugin, bootstrap, interactionPlugin, timeGridPlugin]}
      initialView="dayGridMonth"
      headerToolbar={{
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay',
      }}
      eventClassNames="fc-button"
      themeSystem="bootstrap"
      editable={false}
      selectable={false}
      selectMirror={false}
      dayMaxEvents={2}
      // dateClick={(e) => handleDateClick(e)}
      eventClick={(e) => {
        handleEventClick(e)
      }}
      droppable={false}
      showNonCurrentDates
      fixedWeekCount={false}
      events={sortedData}
      height={700}
      eventOrder={false}
      views={{
        timeGridWeek: {
          dayMaxEvents: 15, // Show 15 events in week view
        },
        timeGridDay: {
          dayMaxEvents: 15, // Show 15 events in day view
        },
      }}
    />
  );
}
