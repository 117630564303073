import React, { useState, useEffect } from "react";
import "./CustomDatePicker.css"; // Make sure this file contains the necessary styles
import { useDispatch, useSelector } from "react-redux";
import { setSelectedDateOnCalendar } from "../../../redux/features/dashboard/dashboardSlice";
import ArrowRightIcon from "../../../assets/svg-icons/ArrowRightIcon";
import ArrowLeftIcon from "../../../assets/svg-icons/ArrowLeftIcon";
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

const dayLabels = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];

const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
};

const getFirstDayOfMonth = (year, month) => {
    return new Date(year, month, 1).getDay();
};

function CustomDatePicker() {
    const today = new Date();
    const dispatch = useDispatch();
    const { selectedDateOnCalendar } = useSelector((state) => state.dashboard);

    // Set initial date to selectedDateOnCalendar if available, otherwise use today
    const [currentDate, setCurrentDate] = useState(new Date(selectedDateOnCalendar) || today);

    // Update currentDate whenever selectedDateOnCalendar changes
    useEffect(() => {
        if (selectedDateOnCalendar) {
            setCurrentDate(new Date(selectedDateOnCalendar));
        }
    }, [selectedDateOnCalendar]);

    const handleNextMonth = () => {
        setCurrentDate((prevDate) => {
            const nextMonth = new Date(prevDate.setMonth(prevDate.getMonth() + 1));
            return nextMonth;
        });
    };

    const handlePreviousMonth = () => {
        setCurrentDate((prevDate) => {
            const prevMonth = new Date(prevDate.setMonth(prevDate.getMonth() - 1));
            return prevMonth;
        });
    };

    const handleDateClick = (day, isCurrentMonth) => {
        if (isCurrentMonth) {
            const selected = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), day));
            console.log("Selected (UTC)", selected.toISOString());
            dispatch(setSelectedDateOnCalendar(selected));
        }
    };

    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const daysInMonth = getDaysInMonth(year, month);
    const firstDayIndex = (getFirstDayOfMonth(year, month) + 6) % 7; // Adjusting to make Monday start the week

    // Get previous month's days to display in empty cells
    const previousMonthDays = getDaysInMonth(year, month - 1);
    const daysToShowFromPreviousMonth = Array.from({ length: firstDayIndex }).map(
        (_, index) => previousMonthDays - firstDayIndex + index + 1
    );

    // Get days from next month to display in empty cells at the end
    const totalCells = 35; // 6 rows of 7 days each
    const nextMonthDaysStart = Array.from({
        length: totalCells - (firstDayIndex + daysInMonth),
    }).map((_, index) => index + 1);

    return (
        <div className="custom-datePicker-container">
            <div className="custom-datePicker-header">
                <h2>
                    {currentDate.toLocaleString("default", { month: "long" })} {year}
                </h2>
                <div>
                    <button type="button" onClick={handlePreviousMonth}>
                        <ArrowLeftIcon />
                    </button>
                    <button type="button" onClick={handleNextMonth}>
                        <ArrowRightIcon />
                    </button>
                </div>
            </div>

            <div className="custom-datePicker-body">
                <div className="custom-datePicker-day-labels">
                    {dayLabels.map((label) => (
                        <div key={label} className="custom-datePicker-day-label">
                            {label}
                        </div>
                    ))}
                </div>

                <div className="custom-datePicker-grid">
                    {/* Display days from the previous month */}
                    {daysToShowFromPreviousMonth.map((day) => (
                        <div key={`prev-${day}`} className="custom-datePicker-cell disabled">
                            {day}
                        </div>
                    ))}

                    {/* Display days of the current month */}
                    {Array.from({ length: daysInMonth }).map((_, index) => {
                        const day = index + 1;

                        const isSelected =
                            selectedDateOnCalendar &&
                            selectedDateOnCalendar.getDate() === day &&
                            selectedDateOnCalendar.getMonth() === month &&
                            selectedDateOnCalendar.getFullYear() === year;

                        const isToday =
                            today.getDate() === day &&
                            today.getMonth() === month &&
                            today.getFullYear() === year;

                        return (
                            <div
                                key={day}
                                className={`custom-datePicker-cell ${isSelected ? "selected" : ""
                                    } ${isToday ? "today" : ""}`}
                                onClick={() => handleDateClick(day, true)}
                            >
                                {day}
                            </div>
                        );
                    })}

                    {/* Display days from the next month */}
                    {nextMonthDaysStart.map((day) => (
                        <div key={`next-${day}`} className="custom-datePicker-cell disabled">
                            {day}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default CustomDatePicker;
